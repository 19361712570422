
























import Vue from 'vue';
import Component from 'vue-class-component';
import store from 'store';
import vuex from '@/store';
import { User, Companies } from '@/store';

@Component({})
export default class CompaniesList extends Vue {
  @User.Action('fetchProjectsData') fetchProjectsData;
  @Companies.Mutation('setSelectedCompany') setSelectedCompany;

  get companies(){
    const user = store.get('user');
    return Object.entries(user.allowed_companies);
  }

  chooseComany(company_id) {
    const user = store.get('user');
    store.set('user', {...user, company: company_id});
    this.setSelectedCompany(company_id);
    this.fetchProjectsData();
    
    this.$router.push('/projects');
  }
}
